<template>
  <div class="android-download">
    <div class="download-btn" @click="downLaodApp" v-if="isDownloadApp && !isIOS">
      <img class="android-logo" src="../../assets/android-logo.png" alt />
      <div class="download-title">
        <span class="title-ZH">安卓版本</span>
        <span class="title-ENG">Android Market</span>
      </div>
    </div>
    <div class="download-btn" @click="downLaodApp" v-if="isDownloadApp && isIOS">
      <img class="android-logo" src="../../assets/ios-logo.png" alt />
      <div class="download-title">
        <span class="title-ZH">苹果ios版本</span>
        <span class="title-ENG">APP Store</span>
      </div>
    </div>
    <div class="download-btn download-loading" v-show="!isDownloadApp">
      <img class="loading-img" src="../../assets/loading.png" alt />
    </div>

    <div class="wx-mask" v-if="isWx">
      <img src="../../assets/shareTips.png" alt />
    </div>
  </div>
</template>

<script>
import { DownloadType } from "./api";
import axios from "axios";
export default {
  data() {
    return {
      isDownloadApp: false,
      isIOS: false,
      isWx: false,
      downloadUrl: "",
      urlObj: {},
    };
  },
  created() { },
  mounted() {

    let _this = this;
    let u = navigator.userAgent;
    this.isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (/(iPhone|iPad|iPod|iOS)/i.test(u)) {
      this.isIOS = true;
      let terminalType = 1;
      this.RequestDownload(terminalType);
      //苹果端
    } else if (/(Android|Adr)/i.test(u)) {
      this.isIOS = false;
      let terminalType = 2;
      this.RequestDownload(terminalType);
      //安卓端
    }
    // 初始化判断是否已下载APP
    function init() {
      try {
        // if (_this.isIOS) {
        //   console.log("ios");
        //   // IOS手机直接打开应用商店，在商店内打开应用
        //   window.location.href =
        //     "https://apps.apple.com/cn/app/%E6%B4%8B%E8%91%B1%E9%9F%A9%E8%AF%AD/id1596422645"; // app store 下载地址
        // } else {
        //   window.location.href = "jxjy://ychy/main"; // 安卓，直接唤醒APP
        // }
        setTimeout(() => {
          //2秒后显示下载按钮
          _this.isDownloadApp = true;
        }, 2000);
      } catch (error) {
        console.log(error);
      }
    }

    function is_weixin() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        _this.isWx = true;
        _this.isDownloadApp = true;
      } else {
        init();
        _this.getUrl();
        _this.Statistics(false);
      }
    }
    is_weixin();


  },
  methods: {
    async Statistics(n = false) {
      if (n && this.urlObj.inviteCode) {
        this.getcopy();
      }
      if (this.urlObj.inviteCode) {
        let type = n ? 1 : 0;
        const data = await DownloadType({
          inviteId: this.urlObj.inviteCode,
          type: type,
        });
      }
    },
    RequestDownload(terminalType) {
      let url = "";
      switch (process.env.NODE_ENV) {
        case "dev":
          url = "http://server-jy-test.jixedu.cn/"
          break;
        case "test":
          url = "http://server-jy-test.jixedu.cn/"
          break;
        default:
          url = "https://server-jy.llsedu.cn/";
          break;
      }
      axios
        .get(url + "common/getChannelVersion", {
          params: {
            channel: 0,
            product: 8,
            terminalType: terminalType,
            loginTerminal: terminalType,
          },
        })
        .then((res) => {
          this.downloadUrl = res.data.data.downloadUrl;
        });
    },
    /* 下载安卓APP */
    downLaodApp() {
      this.Statistics(true);
      let userAgent = window.navigator.userAgent;
      if (this.isIOS) {
        // IOS手机直接打开应用商店，在商店内打开应用
        window.location.href = 'https://apps.apple.com/cn/app/%E6%B4%8B%E8%91%B1%E9%9B%85%E6%80%9D-%E5%89%91%E6%A1%A5lets%E7%9C%9F%E9%A2%98%E6%9C%BA%E7%BB%8F/id6443763900'; // app store 下载地址
        // setTimeout(() => {
        //   this.$toast("暂未开放");
        // }, 500);
      } else {
        //普通下载地址
        console.log("普通下载地址");
        window.location.href = this.downloadUrl; // android 下载地址
      }
    },
    getcopy() {
      const str = `#ycys#:${this.urlObj.inviteCode}`;
      const inputo = document.createElement("input");
      document.body.appendChild(inputo);
      inputo.value = str;
      inputo.setAttribute("readOnly", "readOnly");
      inputo.select();
      document.execCommand("Copy");
      document.body.removeChild(inputo);
    },
    getUrl() {
      var url = window.location.href;
      if (url.includes("?")) {
        var temp1 = url.split("?");
        var pram = temp1[1];
        var keyValue = pram.split("&");
        console.log(keyValue, "keyValue");
        for (var i = 0; i < keyValue.length; i++) {
          // 循环分割后的地址
          var item = keyValue[i].split("=");
          var key = item[0];
          var value = item[1];
          this.urlObj[key] = value;
        }
      }
    },
  },
};
</script>

<style lang="css" scoped>
.android-download {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: url("../../assets/H12315.png") no-repeat center center;
  background-size: cover;
}

.download-btn {
  display: flex;
  align-items: center;
  position: absolute;
  width: calc(100vw * (225 / 414));
  height: calc(100vw * (53 / 414));
  left: 50%;
  /* top: calc(100vw * (622 / 360)); */
  top: calc(100vw * (622 / 414));
  transform: translateX(-50%);
  background: #648eff;
  border-radius: calc(100vw * (6 / 414));
  box-sizing: border-box;
}

.download-title {
  display: flex;
  flex-direction: column;
}

.android-logo {
  width: calc(100vw * (37 / 414));
  margin-left: calc(100vw * (36 / 414));
  margin-right: calc(100vw * (20 / 414));
}

.title-ZH {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: calc(100vw * (16 / 414));
  line-height: calc(100vw * (22 / 414));
  letter-spacing: -0.2px;

  color: #ffffff;
}

.title-ENG {
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: calc(100vw * (14 / 414));
  line-height: calc(100vw * (20 / 414));
  color: #ffffff;
}

.download-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-img {
  width: 30px;
  height: 30px;
  transform-origin: center center;
  animation: loading 1s infinite linear;
}

@keyframes loading {
  from {
    transform: rotateZ(0deg);
  }

  to {
    transform: rotateZ(360deg);
  }
}

.wx-mask {
  position: fixed;
  z-index: 98;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 17px;
}

.wx-mask img {
  width: 100%;
  margin-top: 50px;
}
</style>